<template>
    <div class="bg-white cyrrus-rounded p-4 shadow mt-4 mb-5">
        <p>
            {{$t('rates.form.hint')}}
        </p>
        <b-form
            @submit.prevent="onSubmit"
        >
            <div class="row align-content-end align-items-end">
                <div class="col-12 col-md-3">
                    <form-input-select
                        :options="symbolsFrom"
                        :label="$t('rates.form.from')"
                        id="rates.form.from"
                        v-model="fromSymbol"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <form-input-select
                        :options="symbolsTo"
                        :label="$t('rates.form.to')"
                        id="rates.form.to"
                        v-model="toSymbol"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <b-form-group>
                        <b-btn
                            type="submit"
                            variant="primary"
                        >
                            {{$t('rates.form.submit')}}
                        </b-btn>
                    </b-form-group>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'CreateChartForm',
    props: {
        actualRates: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            fromSymbol: null,
            toSymbol: null
        }
    },
    computed: {
        symbolsFrom () {
            return [...new Set(this.actualRates.map(item => `${item.currencyPair}`.split('_')[0].toUpperCase()))]
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
                .map((symbol) => { return { value: symbol, text: symbol } })
        },
        symbolsTo () {
            if (!this.fromSymbol) {
                return []
            }
            return [...new Set(this.actualRates
                .filter(item => `${item.currencyPair}`.split('_')[0].toUpperCase() === this.fromSymbol)
                .map(item => `${item.currencyPair}`.split('_')[1].toUpperCase()))]
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
                .map((symbol) => { return { value: symbol, text: symbol } })
        }
    },
    watch: {
        fromSymbol (newValue) {
            this.$nextTick(() => {
                this.toSymbol = null
            })
        }
    },
    methods: {
        onSubmit () {
            if (this.$v.$invalid) {
                return this.$notify.error(this.$t('rates.form.error'))
            }
            this.$emit('submit', { currencyPair: `${this.fromSymbol}_${this.toSymbol}` })
            this.fromSymbol = null
            this.toSymbol = null
        }
    },
    validations: {
        fromSymbol: {
            required
        },
        toSymbol: {
            required
        }
    }
}
</script>
