<template>
    <div>
        <loader-overlay
            :loading="saving || ratesLoading"
        >
            <create-chart-form
                :actual-rates="actualRates"
                @submit="onAddChart"
            />
        </loader-overlay>

        <rate-chart
            v-for="item in dashboard"
            :key="item.id"
            :from-symbol="item.fromSymbol"
            :to-symbol="item.toSymbol"
            :chart-id="item.id"
            :last-rates="actualRates"
            :period="item.period"
            @remove="onChartRemove(item.id)"
            @update="onChartUpdate"
        />
    </div>
</template>

<script>
import CreateChartForm from './Components/CreateChartForm.vue'
import ComponentError from '../../../Components/ComponentError.vue'
import ComponentLoader from '../../../Components/ComponentLoader.vue'

export default {
    name: 'RatesDashboardPage',
    components: {
        CreateChartForm,
        RateChart: () => ({
            component: import('./Components/RateChart.vue'),
            loading: ComponentLoader,
            error: ComponentError,
            delay: 10,
            timeout: 1000 * 100
        })
    },
    data () {
        return {
            dashboardLoading: true,
            dashboard: [],
            fromSymbol: null,
            toSymbols: [],
            saving: false,
            ratesLoading: true,
            actualRates: []
        }
    },
    computed: {
        selectedAccount: {
            get () {
                return this.$store.state.selectedAccount
            },
            set (account) {
                this.$store.commit('selectAccount', account)
            }
        }
    },
    mounted () {
        this.loadRates()
        this.loadDashboard()
    },
    watch: {
        selectedAccount (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$nextTick(() => {
                    this.loadRates()
                })
            }
        }
    },
    methods: {
        onAddChart (chartData) {
            this.saving = true
            this.$api.dashboard.create({
                currencyPair: chartData.currencyPair,
                accountId: this.selectedAccount.account_id
            }).then((response) => {
                this.loadDashboard()
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                this.$nextTick(() => {
                    this.saving = false
                })
            })
        },
        onChartRemove (chartId) {
            this.$api.dashboard.delete(chartId)
                .then(() => {
                    this.dashboard = this.dashboard.filter(item => item.id !== chartId)
                }).catch((error) => {
                    console.error(error)
                })
        },
        onChartUpdate (data) {
            this.$api.dashboard.update(data.id, { period: data.period })
                .then(() => {
                    this.dashboard = this.dashboard.map(item => {
                        if (item.id !== data.id) {
                            return item
                        }
                        item.period = data.period
                        return item
                    })
                }).catch((error) => {
                    console.error(error)
                })
        },
        loadRates () {
            if (this.selectedAccount) {
                this.ratesLoading = true
                this.$api.rates.getActualRates(this.selectedAccount.account_id)
                    .then((response) => {
                        if (response.status === 200 && Array.isArray(response.data)) {
                            this.actualRates = response.data
                        }
                    }).catch((error) => {
                        console.error(error)
                    }).finally(() => {
                        this.$nextTick(() => {
                            this.loading = false
                            this.ratesLoading = false
                        })
                    })
            }
        },
        loadDashboard () {
            if (this.selectedAccount) {
                this.dashboardLoading = true
                this.$api.dashboard.getDashboards(this.selectedAccount.account_id)
                    .then((response) => {
                        if (response.status === 200 && Array.isArray(response.data)) {
                            this.dashboard = [...response.data].map(item => {
                                return {
                                    ...item,
                                    fromSymbol: `${item.currencyPair}`.split('_')[0],
                                    toSymbol: `${item.currencyPair}`.split('_')[1]
                                }
                            })
                        }
                    }).catch((error) => {
                        console.error(error)
                    }).finally(() => {
                        this.$nextTick(() => {
                            this.loading = false
                            this.dashboardLoading = false
                        })
                    })
            }
        }
    }
}
</script>
